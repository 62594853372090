@mixin button ($fill: true) {
  border: 2px solid var(--main);
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
  border-radius: 10px;
  font-size: 0.9rem;
  padding: 0.25rem 0.4rem;
  box-sizing: border-box;
  color: var(--main);
  cursor: pointer;
  transition: background-color .1s ease-in-out;

  @if $fill == true {
    color: var(--background-element);
    background-color: var(--main);
  }

  &:hover {
    color: var(--background-element);
    background-color: var(--darkMain);
  }
}
