
.link {
  all: unset;
  height: 100%;
}
.box {
  cursor: pointer;
  overflow: hidden;
  box-shadow: var(--background-highlight) 4px 4px 20px 0;
  transition: transform .1s ease-in-out;
  border-radius: 5px;
  box-sizing: border-box;
  height: 100%;
  background-color: var(--background-element);

  &:hover {
    transform: scale(1.05);
  }

  .image {
    width: 100%;
    height: 10rem;
    background-repeat: no-repeat;
    background-size: cover;
  }

  div {
    padding: .5rem;
    box-sizing: border-box;
    h3 {

    }

    p {
      color: var(--main);
    }
  }
}
