.link {
  all: unset;
}

.box {
  margin-top: 1rem;
  cursor: pointer;
  overflow: hidden;
  height: 4.5rem;
  padding: 8px 0;
  box-sizing: border-box;
  box-shadow: var(--background-highlight) 4px 4px 20px 0;
  transition: transform .1s ease-in-out;
  background-color: var(--background-element);

  &:hover {
    transform: scale(1.05);
  }

  .image {
    height: 100%;
    width: 4rem;
    float: left;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content {
    display: flex;
    align-items: center;
    align-content: center;
    height: 100%;

    h3 {
      padding: 0 10px;
      margin: 0;
      box-sizing: border-box;
      float: left;
    }
  }
}
