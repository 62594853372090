@import "../../../../../mixins";

.main {
  background-color: var(--background-light);
  position: absolute;
  inset: 0;
  min-height: auto;
  overflow-y: scroll;
  padding: 3rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    max-width: 30rem;
    background-color: var(--background);
    width: 100%;
    border-radius: 15px;
    padding: 30px 15px;
    box-sizing: border-box;

    @media only screen and (max-width: 30rem) {
      position: absolute;
      inset: 0;
    }

    h2 {
      text-align: center;
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: space-between;
      gap: 2rem;
      height: 100%;

      div {
        width: 100%;
        input {
          all: unset;
          border: 1px solid var(--background-line);
          padding: 5px;
          box-sizing: border-box;
          width: 100%;
          font-size: 13px;
          border-radius: 5px;
        }

        .error {
          color: var(--red);
          font-size: .9rem;
        }

        .email {

        }

        .password {

        }
      }
      .submit {
        @include button();
      }

      a {
        color: var(--text);
        text-align: center;
        text-decoration: none;
        .no_account {
          text-align: center;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
