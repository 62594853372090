:root {
  --background: #efefef;
  --background-element: #fff;
  --background-line: #c0c0c0;
  --background-light: #e5e5e5;
  --main: #3e72f6;
  --darkMain: #1e5eff;
  --text: #000;
  --gray-text: #6b6b6b;
  --background-highlight: #a8a8a8;
  --red: #ef4636;
  --banner: #4b9d07;
  --banner-link: #0049ff;
  --green: #4b9d07;
  --yellow: #e3bc1d;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #1a1a1a;
    --background-element: #090909;
    --background-line: #c0c0c0;
    --background-light: #2c2c2c;
    --main: #3e72f6;
    --darkMain: #1e5eff;
    --text: #ffffff;
    --background-highlight: #222226;
  }
}
