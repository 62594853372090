@import "../../../../mixins";

.nav {
  width: 100%;
  height: 3rem;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--background-line);
  background-color: var(--background-element);
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    max-width: 1920px;
    padding: 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;

    .buttons_wrapper {
      a {
        button {
          @include button(false);
        }

        .button2 {
          @include button();
          margin-left: 10px;
        }
      }
    }
  }

}

.header {
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  background-color: var(--background-element);
  padding: 0 20px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--background-line);
  z-index: 100;

  .wrapper {
    max-width: 1920px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    h1 {
      margin: 0;
      padding: 0;
      height: fit-content;

      @media only screen and (max-width: 450px) {
        display: none;
      }
    }

    .buttons_wrapper {
      display: flex;
      justify-content: space-between;
      gap: 5px;
      align-items: center;

      button {
        border: 1px solid var(--main);
        font-family: 'Oswald', sans-serif;
        border-radius: 10px;
        font-size: 16px;
        padding: 3px 7px;
        box-sizing: border-box;
        color: var(--background-element);
        background-color: var(--main);
        cursor: pointer;
        transition: background-color .1s ease-in-out;

        &:hover {
          color: var(--background-element);
          background-color: var(--darkMain);
        }
      }

    }
  }

}
.wrapper {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

.footer {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--background-line);
  border-top: 1px solid var(--background-line);
  background-color: var(--background-element);

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    button {
      @include button();
    }
  }
}
