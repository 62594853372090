@use "variables";
@use '@carbon/react';
@use '@carbon/styles/scss/themes' as *;
@use '@carbon/themes';
@use '@carbon/styles/scss/theme';


@media (prefers-color-scheme: dark) {
  * {
    @include themes.theme($g100);
  }
}


@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
body {
  margin: 0;
  //font-family: 'Lato', sans-serif;
  font-family: 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ql-syntax {
  background-color: var(--background-line);
  color: #fff;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  padding: 1rem;
  border-radius: 5px;
}
