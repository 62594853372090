.main {
  max-width: 1920px;
  padding: 20px;
  box-sizing: border-box;
  min-height: calc(100vh - 11rem);

  .sort {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 20px;

    p {
      font-size: 18px;
    }

    div {
      max-width: 20rem;
      width: 100%;
    }
  }

  .articles {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    align-items: flex-start;

    @media only screen and (max-width: 800px) {
      flex-wrap: wrap;
    }

    .boxes {
      flex-basis: 75%;
      display: grid;
      gap: 30px 20px;
      grid-template-columns: repeat(4, minmax(0, 1fr));

      .skeleton {
        width: 100%;
        height: 14rem;
      }

      .banner {
        grid-column: 1 / 5;
        background: url("../../../../../assets/triangles.svg") no-repeat center;
        background-size: cover;
        color: #fff;
        font-family: 'Poppins', sans-serif;
        padding: 3rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;

        @media only screen and (max-width: 90rem) {
          grid-column: 1 / 4;
        }

        @media only screen and (max-width: 68rem) {
          grid-column: 1 / 3;
        }

        @media only screen and (max-width: 50rem) {
          grid-column: 1 / 2;
        }

        h1 {
          font-weight: 600;
          font-size: 40px;
          text-align: center;
        }

        a {
          color: var(--banner-link);
          font-size: 25px;
          font-weight: 500;
          text-align: center;
          background-color: var(--background);
          padding: 1rem;
          width: fit-content;
          transition: transform .1s ease-in-out;
          border-radius: 3px;

          &:hover {
            text-decoration: underline;
            transform: scale(1.05);
          }
        }

      }

      @media only screen and (max-width: 90rem) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @media only screen and (max-width: 68rem) {
        flex-basis: 60%;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media only screen and (max-width: 50rem) {
        flex-basis:100%;
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }

    .most_liked {
      flex-basis: 25%;

      .skeleton {
        height: 4rem;
        margin-bottom: 0.5rem;
        width: 100%;
      }

      @media only screen and (max-width: 68rem) {
        flex-basis: 40%;
      }

      @media only screen and (max-width: 50rem) {
        flex-basis:100%;
      }

      h2 {
        color: var(--main);
      }
    }
  }
}
