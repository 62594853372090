@import "../../../../../mixins";

.main {
  margin: 2rem 4rem;
  max-width: 200rem;

  @media only screen and (max-width: 50rem) {
    margin: 2rem 0;
  }

  form {
    .title {
      all: unset;
      border: 1px solid var(--background-line);
      padding: 1rem;
      box-sizing: border-box;
      width: 100%;
      font-size: 1.5rem;
      border-radius: 5px;
    }

    .loading {
      justify-content: center;
    }

    .submit {
      @include button(false);
      width: 100%;
      font-size: 1.2rem;
      padding: .5rem .5rem;
    }
    div {
      background-color: var(--background);
      color: var(--text);

      span {
        @media (prefers-color-scheme: dark) {
          color: var(--background);
        }
      }

      .tags {
        color: var(--text);
        background-color: var(--background);
      }
    }
  }
}

.drop_container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
  color: var(--text);
}

.drop_container:hover {
  background: var(--background-highlight);
  border-color: #111;
}

.drop_container:hover .drop_title {
  color: var(--text);
}

.drop_title {
  color: var(--text);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

input[type=file] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: var(--background-element);
  border-radius: 10px;
  border: 1px solid #555;
}

input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #0d45a5;
}
