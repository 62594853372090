@import "/src/mixins";

.main {
  position: relative;
  min-height: calc(100vh - 11rem);
  position: relative;
  padding: 0 1rem 1rem;

  .author_header {
    background-color: var(--background-line);
    margin: 0 -1rem 0 -1rem;
    padding: 1rem;
    overflow: hidden;
    position: sticky;
    top: 5rem;
    border-bottom: 1px solid var(--gray-text);

    div {
      float: right;
      overflow: hidden;
      display: flex;
      gap: .5rem;

      button {
        @include button();

        &:hover {
          opacity: 0.8;
        }

        &:first-child {
          color: #fff;
          background-color: var(--red);
          border: 1px solid transparent;
        }

        &:last-child {
          color: #fff;
          background-color: var(--gray-text);
          border: 1px solid transparent;
        }
      }
    }
  }

  .image {
    object-fit: cover;
    max-height: 130vh;
  }

  .title {
    border: 2px solid var(--background-line);
    margin: 0;
    padding: .2rem .5rem;
    width: fit-content;
    background: var(--background);
    position: relative;
    top: -1.5rem;
    left: 1rem;
    font-weight: 500;
  }

  .content {
    blockquote {
      border-left: 2px solid var(--background-line);
      padding-left: .5rem;
      color: var(--gray-text);
      font-family: sans-serif;
    }
    img {
      width: 100%;
    }
  }

  .download {
    width: fit-content;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    display: flex;

    p {
      margin: 0;
      width: fit-content;
      background-color: var(--green);
      padding: .5rem 1rem;
      border-radius: 10px;
      font-weight: 500;
      overflow: hidden;
    }
  }

  .author {
    span {
      padding: .1rem .4rem;
      border-radius: 5px;
      background-color: var(--yellow);
    }
  }

  .tags {
    .tag {
      float: left;
      color: #fff;
      background-color: var(--main);
      width: fit-content;
      margin-right: .5rem;
      padding: .2rem;
      border-radius: 4px;
    }
    padding-bottom: 1rem;
  }

}
