a {
  all: unset;
  cursor: pointer;

  .box {
    display: flex;
    margin-bottom: 0.4rem;

    .image {
      width: 4rem;
      height: inherit;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .info {
      p {
        color: var(--main);
      }
    }
  }
}
